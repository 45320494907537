import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';

import Image from '~/components/Image';

export const Styled = styled.div`
  padding: 6rem 0;
  z-index: 1;
  .container {
    padding: 0;
  }
  h2 {
    margin-top: 8px;
    color: ${colors.BLUE_DARK()};
  }

  .screenshot {
    border-radius: 4px;
    overflow: hidden;
    box-shadow: ${styles.BOX_SHADOW};
  }
  .section__heading {
    margin-bottom: 4rem;
    padding: 0 2rem;
  }
  .section__props__prop {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'left right';
    margin-bottom: 4rem;
  }
  .section__props__prop:last-child {
    margin: 0;
  }
  .section__props__prop__text {
    width: 70%;
    padding: 0 2rem;
  }
  .section__props__prop__text h4 {
    color: ${colors.BLUE_DARK()};
    margin-bottom: 12px;
  }
  .section__props__prop__content {
    position: relative;
  }

  /* Product Specific Styles */
  .screenshot--product-quote {
    position: relative;
    z-index: 1;
  }
  .discussion {
    position: absolute;
    width: 2px;
    background: ${colors.PURPLE_LIGHT(0.2)};
    height: 300px;
    top: 35%;
    left: 5%;
    z-index: 2;
  }
  .discussion__bubble {
    width: 100%;
    height: 20px;
    position: absolute;
    top: 0;
    right: 0;
    background: ${colors.PURPLE_LIGHT(0.5)};
    animation: discussion-bubble 6s infinite linear;
  }
  .discussion__bubble:nth-child(2) {
    animation-delay: 2s;
  }
  .discussion__bubble:nth-child(3) {
    animation-delay: 4s;
  }
  .discussion-screenshots .screenshot {
    position: relative;
    margin-bottom: 1rem;
    width: 75%;
    z-index: 3;
  }
  .screenshot--personas-filter {
    width: 75%;
    z-index: 2;
    position: relative;
  }
  .personas-screenshots__topics {
    width: 75%;
    margin-left: 25%;
    margin-top: -24px;
  }
  .personas-screenshots__topics .gatsby-image-wrapper {
    margin-bottom: 12px;
  }

  /* CS Specific Styles */
  .champion-screenshots .screenshot {
    width: 75%;
    margin-bottom: 12px;
    &:nth-child(2) {
      margin-left: 25%;
    }
    &:nth-child(3) {
      margin-left: 5%;
    }
  }
  .holistic-screenshots,
  .touch-screenshots {
    position: relative;
  }
  .screenshot--holistic-account {
    width: 50%;
    margin-bottom: 48px;
  }
  .screenshot--holistic-profile {
    width: 60%;
    position: absolute;
    right: 0;
    top: 48px;
  }
  .screenshot--notification {
    width: 60%;
    position: relative;
    z-index: 2;
  }
  .screenshot--email {
    margin-top: -32px;
    width: 80%;
    margin-left: 20%;
  }
  .screenshot--trust {
    width: calc(100% - 24px);
    box-shadow: 24px 24px 0px ${colors.PURPLE_LIGHT()};
  }

  &.dark {
    background: ${colors.BLUE_DARK()};
    h2,
    h4,
    label {
      color: ${colors.WHITE()};
    }
    p {
      color: ${colors.WHITE(0.6)};
    }
    .section__props__prop__text {
      grid-area: right;
      margin-left: 10%;
    }
  }

  @keyframes discussion-bubble {
    0% {
      top: 0;
    }
    100% {
      top: 100%;
    }
  }

  @media screen and (max-width: 1200px) {
    .section__props__prop__text {
      width: 100%;
    }
    &.dark .section__props__prop__text {
      margin-left: 0%;
    }
  }

  @media screen and (max-width: 800px) {
    .section__props__prop {
      grid-template-columns: 1fr;
      grid-template-areas:
        'left'
        'bottom';
    }
    .section__props__prop__content {
      margin-top: 2rem;
    }
    &.dark .section__props__prop__text {
      grid-area: left;
      margin-left: 0%;
    }

    .discussion {
      top: 40%;
    }
    .discussions .section__props__prop__text {
      padding-left: 10%;
    }
    .discussion-screenshots .screenshot {
      width: 100%;
    }
    .screenshot--personas-filter {
      width: 90%;
    }
    .personas-screenshots__topics {
      width: 90%;
      margin-left: 10%;
      margin-top: -8px;
    }

    .champion-screenshots .screenshot {
      width: 100%;
      &:nth-child(2) {
        margin-left: 0%;
      }
      &:nth-child(3) {
        margin-left: 0%;
      }
    }
    .screenshot--holistic-account {
      width: 65%;
      margin-bottom: 64px;
    }
    .screenshot--holistic-profile {
      width: 75%;
      top: 120px;
    }
    .screenshot--notification {
      width: 80%;
    }
    .screenshot--email {
      margin-top: -24px;
      width: 95%;
      margin-left: 5%;
    }
    .screenshot--trust {
      width: calc(100% - 16px);
      box-shadow: 16px 16px 0px ${colors.PURPLE_LIGHT()};
    }
  }
`;

interface IHomepageSectionProps {
  className?: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
}

export const HomepageSection: React.FC<IHomepageSectionProps> = (props) => {
  const { className, title, description } = props;
  return (
    <div className={`section__props__prop ${className}`}>
      <div className="section__props__prop__text">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
      <div className="section__props__prop__content">{props.children}</div>
    </div>
  );
};

const HomepageProductTeams: React.FC = () => {
  return (
    <Styled id="product">
      <Container className="container">
        <div className="section__heading">
          <label>Herald for</label>
          <h2>Product Teams</h2>
        </div>
        <div className="section__props">
          <HomepageSection
            title="Consolidate customer feedback."
            description="With Herald, customer problems don't get lost in CRM, Slack, or email threads. Gain analytical superpowers. Recall any feedback from any customer on any issue. See every customer with a given problem instantly."
          >
            <div className="screenshot screenshot--product-quote">
              <Image src="screenshots/elements/product-single-quote.png" />
            </div>
            <div className="discussion__container">
              <div className="discussion">
                <div className="discussion__bubble" />
                <div className="discussion__bubble" />
                <div className="discussion__bubble" />
              </div>
            </div>
          </HomepageSection>

          <HomepageSection
            className="discussions"
            title="Streamline discussions and followups."
            description="With user feedback consolidated in Herald, streamline and automate followups to gain clarity on the customer's problem. "
          >
            <div className="discussion-screenshots">
              <div className="screenshot">
                <Image src="screenshots/elements/product-single-quote-followup1.png" />
              </div>
              <div className="screenshot">
                <Image src="screenshots/elements/product-single-quote-followup2.png" />
              </div>
            </div>
          </HomepageSection>

          <HomepageSection
            title="Uncover opportunities to accelerate growth and revenue."
            description="Herald integrates with Salesforce, Segment, and Intercom. Hone in on problems for specific customer personas. Precisely understand the unsolved pains of churned customers or solutions needed by price-insensitive customers."
          >
            <div className="personas-screenshots">
              <div className="screenshot screenshot--personas-filter">
                <Image src="screenshots/elements/product-personas-filter.png" />
              </div>
              <div className="personas-screenshots__topics">
                <Image src="screenshots/elements/product-personas-topic1.png" />
                <Image src="screenshots/elements/product-personas-topic2.png" />
                <Image src="screenshots/elements/product-personas-topic3.png" />
              </div>
            </div>
          </HomepageSection>

          <HomepageSection
            title="Increase transparency about your product strategy."
            description="Herald enables product teams to get customer facing teams aligned on product strategy. Relay decisions efficiently: easily update all account owners about the status of customer-requested features with a single click."
          >
            <div className="screenshot">
              <Image src="screenshots/elements/product-transparency.png" />
            </div>
          </HomepageSection>
        </div>
      </Container>
    </Styled>
  );
};

export default HomepageProductTeams;
