import React from 'react';

import { Container } from '~/layout/Layout';
import {
  Styled,
  HomepageSection,
} from '~/components/homepage/HomepageProductTeams';
import Image from '~/components/Image';

const HomepageProductTeams: React.FC = () => {
  return (
    <Styled className="dark" id="services">
      <Container className="container">
        <div className="section__heading">
          <label>Herald for</label>
          <h2>Customer Teams</h2>
        </div>
        <div className="section__props">
          <HomepageSection
            title="Champion the voice of the customer."
            description="Capturing, organizing, and sharing customer feedback is a breeze with Herald. Get requests from your accounts reviewed by product team consistently. Easily see the latest status of every customer ask."
          >
            <div className="champion-screenshots">
              <div className="screenshot">
                <Image src="screenshots/elements/cs-quote1.png" />
              </div>
              <div className="screenshot">
                <Image src="screenshots/elements/cs-quote2.png" />
              </div>
              <div className="screenshot">
                <Image src="screenshots/elements/cs-quote3.png" />
              </div>
            </div>
          </HomepageSection>

          <HomepageSection
            title="Get a holistic view of an account."
            description="Review the status of all requests from a given account. Integrate data from Salesforce, Segment, and Intercom to enrich your view."
          >
            <div className="holistic-screenshots">
              <div className="screenshot screenshot--holistic-account">
                <Image src="screenshots/elements/cs-account.png" />
              </div>
              <div className="screenshot  screenshot--holistic-profile">
                <Image src="screenshots/elements/cs-account-profile.png" />
              </div>
            </div>
          </HomepageSection>

          <HomepageSection
            title="Collateral to help with re-engagement and account management."
            description="Provide customers a private view with up-to-date status of their asks.  Use completed items as collateral during account renewals and expansion. Leverage it to re-engage with a lost prospect or churned customer."
          >
            <div className="screenshot screenshot--trust">
              <Image src="screenshots/elements/cs-history.png" />
            </div>
          </HomepageSection>

          <HomepageSection
            title="Increase customer touch points."
            description="Get notified when a feature one of your accounts asked for ships—reach out to pass on the good news. Get your most eager customers as beta testers for new products."
          >
            <div className="touch-screenshots">
              <div className="screenshot screenshot--notification">
                <Image src="screenshots/elements/cs-notification.png" />
              </div>
              <div className="screenshot  screenshot--email">
                <Image src="screenshots/engage-gmail.png" />
              </div>
            </div>
          </HomepageSection>
        </div>
      </Container>
    </Styled>
  );
};

export default HomepageProductTeams;
