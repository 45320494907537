import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import { icons, colors, styles } from 'herald-fe-shared';

import Image from '~/components/Image';

export const COMPANIES = {
  divjoy: { name: 'Divjoy', url: 'https://www.divjoy.com' },
  beacons: { name: 'Beacons', url: 'https://beacons.ai/' },
  edley: { name: 'Edley', url: 'https://www.edley.de' },
  withfriends: { name: 'Withfriends', url: 'https://www.withfriends.co' },
  cuanto: { name: 'Cuanto', url: 'https://www.cuantopago.com/' },
};

export const PEOPLE = {
  gabe: {
    name: 'Gabe Ragland',
    role: 'Founder and CEO',
    avatar: 'gabe.jpg',
    company: COMPANIES.divjoy,
  },
  neal: {
    name: 'Neal Jean',
    role: 'Co-Founder and CEO',
    avatar: 'neal.jpg',
    company: COMPANIES.beacons,
  },
  giacomo: {
    name: 'Giacomo Hendel',
    role: 'Founder and CEO',
    avatar: 'giacomo.jpeg',
    company: COMPANIES.edley,
  },
  joe: {
    name: 'Joe Ahearn',
    role: 'Co-Founder',
    avatar: 'joe.jpg',
    company: COMPANIES.withfriends,
  },
  jorge: {
    name: 'Jorge Garcia',
    role: 'Co-Founder and Chief Product Officer',
    avatar: 'jorge.jpg',
    company: COMPANIES.cuanto,
  },
};

const TESTIMONIALS = [
  {
    id: 'divjoy',
    person: PEOPLE.gabe,
    quote: (
      <>
        In the past I had conversations with users, but in the back of my mind I
        knew I was going to forget most of them. I now have the confidence to
        engage more deeply with users because I can drop feedback into Herald
        and never lose it.
      </>
    ),
  },
  {
    id: 'beacons',
    person: PEOPLE.neal,
    quote: (
      <>
        Herald allows us to stay organized and on top of all the feedback we're
        getting. Before Herald, I had no idea what my co-founders were talking
        to customers about unless they brought it up, so it'd just get lost.
      </>
    ),
  },
  {
    id: 'edley',
    person: PEOPLE.giacomo,
    quote: (
      <>
        Herald brings all my user feedback in one place. Before, I was using
        four tools—Asana, Google Docs, Apple Notes and Numbers to manage it. Now
        I have one, and I know exactly what I need to work on.
      </>
    ),
  },
  {
    id: 'withfriends',
    person: PEOPLE.joe,
    quote: (
      <>
        We used to post feedback to Slack and had conversations about it
        randomly. With Herald, our users can talk directly to all my teammates.
        That would never have been possible before, especially not as we grew.
      </>
    ),
  },
  {
    id: 'cuanto',

    person: PEOPLE.jorge,
    quote: (
      <>
        As a seed stage company, we use a Northstar metric to move the needle on
        a big objective. Herald helps us balance that with day-to-day issues
        that crop up with our customers. It's easier to make them happy, and
        close the loop and let them know when we do things.
      </>
    ),
  },
];

const SEGMENT_LENGTH_MILLISECONDS = 10000;
const SEGMENT_TRANSITION_LENGTH_MILLISECONDS = 500;

const Styled = styled.div`
  margin: auto;
  position: relative;
  height: 16rem;
  .quote-icon {
    position: absolute;
    left: -150px;
    top: -60px;
    width: 40%;
    height: 100%;
    z-index: 0;
    fill: ${colors.PURPLE_LIGHT(0.05)};
  }
  .quote-icon--right {
    left: initial;
    top: initial;
    bottom: -60px;
    right: -150px;
  }
  .arrow {
    position: absolute;
    top: calc(50% - 1.5rem);
    left: -1.5rem;
    background: #f9f0fb;
    border: 1px solid ${colors.PURPLE_LIGHT(0.2)};
    transition: 250ms all;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;
  }
  .arrow svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${colors.PURPLE_LIGHT()};
  }
  .arrow--right {
    left: initial;
    right: -1.5rem;
  }
  .arrow:hover {
    border: 1px solid ${colors.PURPLE_LIGHT(1)};
  }
  .enter {
    opacity: 0;
    transform: translateX(30px);
  }
  .enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: all ${SEGMENT_TRANSITION_LENGTH_MILLISECONDS}ms;
  }
  .exit {
    opacity: 1;
    transform: translateX(0px);
  }
  .exit-active {
    opacity: 0;
    transform: translateX(-30px);
    transition: all ${SEGMENT_TRANSITION_LENGTH_MILLISECONDS}ms;
  }
  .testimonial {
    padding: 3rem;
    height: 16rem;
    width: 100%;
    max-width: 60rem;
    margin: auto;
    border-radius: 5px;
    background: ${colors.WHITE()};
    box-shadow: ${styles.BOX_SHADOW};
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  .testimonial__avatar {
    flex: 0 0 6rem;
    width: 6rem;
    height: 6rem;
    margin-right: 3rem;
    border-radius: 50%;
    overflow: hidden;
    filter: grayscale();
  }
  .testimonial__text p {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .testimonial__text__user__avatar {
    display: none;
  }

  @media screen and (max-width: 800px) {
    height: 24rem;
    .testimonial {
      height: auto;
    }
  }
  @media screen and (max-width: 500px) {
    .testimonial__avatar {
      display: none;
    }
    .testimonial__text__user__avatar {
      display: initial;
    }
    .testimonial__text__user__avatar {
      display: initial;
    }
  }
`;

let interval: any;

const StyledPersonBio = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  .testimonial__text__user__avatar {
    flex: 0 0 3rem;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    border-radius: 50%;
    overflow: hidden;
    filter: grayscale();
  }
  label {
    font-size: 1rem;
  }
  h5 {
    margin-top: 0.25rem;
    font-size: 0.8rem;
    color: ${colors.GRAY_3()};
  }
`;

export const PersonBio: React.FC<any> = (props) => {
  const { avatar, name, role, company } = props;
  return (
    <StyledPersonBio>
      <div className="testimonial__text__user__avatar">
        <Image src={`headshots/${avatar}`} />
      </div>
      <div className="testimonial__text__user__name">
        <label>{name}</label>
        <h5>
          {role} at{' '}
          <a href={company.url} target="_blank">
            {company.name}
          </a>
        </h5>
      </div>
    </StyledPersonBio>
  );
};

const TestimonialsCarousel: React.FC = () => {
  const [segment, setSegment] = useState(0);

  const advance = useCallback(
    (step: number) => {
      let newStep = segment + step;
      if (newStep > TESTIMONIALS.length - 1) {
        newStep = 0;
      } else if (newStep < 0) {
        newStep = TESTIMONIALS.length - 1;
      }
      clearInterval(interval);
      setSegment(-1);
      setTimeout(
        () => setSegment(newStep),
        SEGMENT_TRANSITION_LENGTH_MILLISECONDS
      );
    },
    [segment]
  );

  useEffect(() => {
    interval = setInterval(() => {
      const s = segment;
      setSegment(-1);
      setTimeout(() => {
        setSegment(() => {
          if (s === TESTIMONIALS.length - 1) {
            return 0;
          }
          return s + 1;
        });
      }, SEGMENT_TRANSITION_LENGTH_MILLISECONDS);
    }, SEGMENT_LENGTH_MILLISECONDS);
    return () => {
      clearInterval(interval);
    };
  }, [segment]);

  return (
    <Styled>
      <icons.QuoteLeft className="quote-icon" />
      <icons.QuoteRight className="quote-icon quote-icon--right" />
      <div className="arrow arrow--left" onClick={() => advance(-1)}>
        <icons.AngleLeft />
      </div>
      {TESTIMONIALS.map((s, i) => {
        return (
          <CSSTransition
            key={s.id}
            in={i === segment}
            timeout={SEGMENT_TRANSITION_LENGTH_MILLISECONDS}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <div className="testimonial">
              <div className="testimonial__avatar">
                <Image src={`headshots/${s.person.avatar}`} />
              </div>
              <div className="testimonial__text">
                <p className="text-no-margin">{s.quote}</p>
                <PersonBio {...s.person} />
              </div>
            </div>
          </CSSTransition>
        );
      })}
      <div className="arrow arrow--right" onClick={() => advance(1)}>
        <icons.AngleRight />
      </div>
    </Styled>
  );
};

export default TestimonialsCarousel;
