import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Page from '~/layout/LayoutMarketing';
import Section from '~/components/Section';
import LargeCTA from '~/components/LargeCTA';
import Grid from '~/components/startups/HomepageGrid';

import TestimonialsCarousel from '~/components/startups/TestimonialsCarousel';

import HomepageOpener from '~/components/homepage/HomepageOpener';
import HomepageProduct from '~/components/homepage/HomepageProductTeams';
import HomepageCS from '~/components/homepage/HomepageCustomerSuccess';
import HomepageStartups from '~/components/homepage/HomepageStartups';

const Styled = styled.div`
  @media screen and (max-width: 400px) {
  }
`;

const IndexPage: React.FC = () => {
  return (
    <Page
      title="A Collaborative Repository for User Feedback."
      description="Herald helps product and customer teams work together to
          grow revenue by painting a full picture of user problems."
      navDark={true}
    >
      <Styled>
        <Grid />

        <HomepageOpener />
        <HomepageProduct />
        <HomepageCS />
        <HomepageStartups />

        <Section className="section--testimonials">
          <Container className="container">
            <TestimonialsCarousel />
          </Container>
        </Section>
        <LargeCTA
          demo={false}
          title="Ready to grow revenue?"
          description="Herald helps product and customer teams work together to
              grow revenue by painting a full picture of user problems."
        />
      </Styled>
    </Page>
  );
};

export default IndexPage;
