import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { components, colors, icons } from 'herald-fe-shared';
import { Link } from 'gatsby';

import Image from '~/components/Image';
import { clearInterval } from 'timers';

const HERALD_PULSE_WIDTH = 150;
const PIPE_COLOR = `#103B78`;

const Styled = styled.div`
  margin: 4rem 0;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 4fr 1fr ${HERALD_PULSE_WIDTH}px 1fr 4fr 1fr 4fr;
  grid-template-areas: 'customer channels arrow-services services arrow-herald herald arrow-product product arrow-eng eng';

  .diagram__customer {
    grid-area: customer;
  }
  .diagram__channels {
    grid-area: channels;
  }
  .diagram__services {
    grid-area: services;
  }
  .diagram__product {
    grid-area: product;
  }
  .diagram__eng {
    grid-area: eng;
  }
  .diagram__arrow--services {
    grid-area: arrow-services;
  }
  .diagram__arrow--herald {
    grid-area: arrow-herald;
  }
  .diagram__arrow--product {
    grid-area: arrow-product;
  }
  .diagram__arrow--eng {
    grid-area: arrow-eng;
  }

  .arrow {
    border: solid ${PIPE_COLOR};
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 5px;
    transform: rotate(-45deg);
    position: absolute;
    left: calc(100% - 10px);
    top: calc(50% - 10px);
  }
  .diagram__arrow {
    position: relative;
    height: 0;
    border-top: 5px solid ${PIPE_COLOR};
    align-self: center;
  }

  .diagram__customer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  .diagram__customer h5 {
    color: ${colors.WHITE()};
    font-size: 12px;
    margin: 0;
    margin-top: 4px;
  }
  .diagram__customer svg {
    width: 2rem;
    height: 2rem;
    fill: ${colors.WHITE()};
  }

  .diagram__channels {
    height: 100%;
    border-top: 5px solid ${PIPE_COLOR};
    border-bottom: 5px solid ${PIPE_COLOR};
    border-right: 5px solid ${PIPE_COLOR};
    border-radius: 4px;
  }
  .channels {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: calc(100% + 32px);
    margin-top: -16px;
  }
  .channel {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${colors.WHITE()};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .channel .gatsby-image-wrapper {
    width: 50%;
  }

  .diagram__functions {
    margin: 1rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 2rem);
  }
  .diagram__functions__function {
    color: ${colors.WHITE()};
    border-top: 3px solid ${colors.WHITE(0.2)};
    margin-bottom: 16px;
    font-size: 16px;
    padding: 12px;
    background: ${PIPE_COLOR};
    border-radius: 4px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: 250ms all;
  }
  .diagram__functions__function:hover {
    background: ${colors.WHITE(0.2)};
  }
  .diagram__functions__function--link {
    width: 100%;
  }
  .diagram__functions__function--link:last-child .diagram__functions__function {
    margin: 0;
  }

  .diagram__herald {
    align-self: center;
    width: 100%;
    height: ${HERALD_PULSE_WIDTH}px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .diagram__herald__logo {
    background: ${colors.WHITE()};
    width: 40%;
    height: 40%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .diagram__herald__logo__pulse {
    opacity: 0;
    position: absolute;
    width: 150%;
    height: 150%;
    border-radius: 50%;
    background: ${colors.PURPLE_LIGHT(0.3)};
    transform-origin: center center;
    animation: pulse 4s infinite linear;
    border: 4px solid ${colors.PURPLE_LIGHT(0.3)};
  }
  .diagram__herald__logo__pulse:nth-child(2) {
    animation-delay: 1s;
  }
  .diagram__herald__logo__pulse:nth-child(3) {
    animation-delay: 2s;
  }

  .diagram__eng {
    margin-left: 1rem;
    height: 100%;
    border-left: 5px solid ${PIPE_COLOR};
    border-top: 5px solid ${PIPE_COLOR};
    border-bottom: 5px solid ${PIPE_COLOR};
    border-radius: 4px;
  }
  .diagram__eng__bubbles {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-end;
    height: calc(100% + 64px);
    margin-top: -32px;
  }
  .diagram__eng__bubble {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: ${PIPE_COLOR};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid ${colors.WHITE(0.2)};
  }
  .diagram__eng__bubble h5 {
    color: ${colors.WHITE()};
    font-size: 12px;
  }

  @keyframes pulse {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }

  @media screen and (max-width: 800px) {
    margin: 2rem 0;
    grid-template-columns: 10% 5% 27% 5% 70px 5% calc(48% - 70px);
    grid-template-rows: 1fr 2fr;
    grid-template-areas:
      'customer arrow-services services arrow-herald herald arrow-product product'
      'channels arrow-services services arrow-herald herald arrow-product product';

    .diagram__eng,
    .diagram__arrow--eng {
      display: none;
    }
    .diagram__herald {
      height: 70px;
    }
    .diagram__herald,
    .diagram__services,
    .diagram__product,
    .diagram__arrow {
      grid-row: 1 / 3;
    }
    .diagram__customer h5 {
      font-size: 9px;
      margin-top: 2px;
    }
    .diagram__customer svg {
      width: 20px;
      height: 20px;
    }
    .diagram__channels {
      margin-top: 12px;
    }
    .diagram__herald__logo {
      width: 60%;
      height: 60%;
    }
    .diagram__herald__logo__pulse {
      width: 160%;
      height: 160%;
    }
    .diagram__functions__function {
      padding: 6px;
      font-size: 12px;
    }
    .channel {
      width: 24px;
      height: 24px;
    }
    .channel .gatsby-image-wrapper {
      width: 60%;
    }
  }
`;

const LOGO_1 = ['intercom', 'slack'];
const LOGO_2 = ['gmail', 'zendesk'];
const LOGO_3 = ['zoom', 'salesforce'];
const LOGO_4 = ['front-symbol'];

const Diagram: React.FC = () => {
  const [logo1, setLogo1] = useState(LOGO_1[0]);
  const [logo2, setLogo2] = useState(LOGO_2[0]);
  const [logo3, setLogo3] = useState(LOGO_3[0]);
  const [logo4] = useState(LOGO_4[0]);

  useEffect(() => {
    const f1 = () => setLogo1(logo1 === LOGO_1[0] ? LOGO_1[1] : LOGO_1[0]);
    window.setTimeout(f1, 4000);
  }, [logo1]);

  useEffect(() => {
    const f2 = () => setLogo2(logo2 === LOGO_2[0] ? LOGO_2[1] : LOGO_2[0]);
    window.setTimeout(f2, 6000);
  }, [logo2]);

  useEffect(() => {
    const f3 = () => setLogo3(logo3 === LOGO_3[0] ? LOGO_3[1] : LOGO_3[0]);
    window.setTimeout(f3, 5000);
  }, [logo3]);

  return (
    <Styled>
      <div className="diagram__customer">
        <icons.UserFriends />
        <h5>Customer</h5>
      </div>

      <div className="diagram__channels">
        <div className="channels">
          <div className="channel">
            <Image src={`logos/${logo1}.png`} />
          </div>
          <div className="channel">
            <Image src={`logos/${logo2}.png`} />
          </div>
          <div className="channel">
            <Image src={`logos/${logo3}.png`} />
          </div>
          <div className="channel">
            <Image src={`logos/${logo4}.png`} />
          </div>
        </div>
      </div>

      <div className="diagram__arrow diagram__arrow--services">
        <i className="arrow" />
      </div>

      <div className="diagram__services">
        <div className="diagram__functions">
          <Link to="#services" className="diagram__functions__function--link">
            <h5 className="diagram__functions__function">Sales</h5>
          </Link>
          <Link to="#services" className="diagram__functions__function--link">
            <h5 className="diagram__functions__function">Success</h5>
          </Link>
          <Link to="#services" className="diagram__functions__function--link">
            <h5 className="diagram__functions__function">Support</h5>
          </Link>
        </div>
      </div>

      <div className="diagram__arrow diagram__arrow--herald">
        <i className="arrow" />
      </div>

      <div className="diagram__herald">
        <div className="diagram__herald__logo">
          <components.Logo type="symbol" width={'60%' as any} color="dark" />
        </div>
        <div className="diagram__herald__logo__pulse" />
        <div className="diagram__herald__logo__pulse" />
        <div className="diagram__herald__logo__pulse" />
      </div>

      <div className="diagram__arrow diagram__arrow--product">
        <i className="arrow" />
      </div>

      <div className="diagram__product">
        <div className="diagram__functions">
          <Link to="#product" className="diagram__functions__function--link">
            <h5 className="diagram__functions__function">Product</h5>
          </Link>
        </div>
      </div>

      <div className="diagram__arrow diagram__arrow--eng">
        <i className="arrow" />
      </div>

      <div className="diagram__eng">
        <div className="diagram__eng__bubbles">
          <div className="diagram__eng__bubble">
            <h5>Eng</h5>
          </div>
          <div className="diagram__eng__bubble">
            <h5>Design</h5>
          </div>
        </div>
      </div>
    </Styled>
  );
};

export default Diagram;
