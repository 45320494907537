import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, icons } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';
import Section from '~/components/Section';
import Image from '~/components/Image';
// import CTADemo from '~/components/signup/EmailInput';
import Diagram from '~/components/homepage/Diagram';

const Styled = styled.div`
  .opener {
    margin-top: -2rem;
    padding-top: 6rem;
    padding-bottom: 4rem;
    position: relative;
  }
  .section__gradient {
    background: linear-gradient(180deg, #00122c 0%, #003b8f 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .opener h1 {
    font-size: 3rem;
    line-height: 3.3rem;
    margin: 1.5rem 0;
    color: ${colors.WHITE()};
    text-align: center;
  }
  .opener p {
    font-size: 1.5rem;
    line-height: 1.9rem;
    color: ${colors.WHITE(0.8)};
    text-align: center;
    max-width: 40rem;
    margin: auto;
  }
  .opener .container {
    flex-flow: row wrap;
    max-width: 60rem;
    margin: auto;
  }
  .opener .signup-form {
    margin: 2rem auto;
    margin-top: 5rem;
    max-width: 40rem;
    display: flex;
    justify-content: center;
  }
  .opener .signup-form .input__container {
    background: ${colors.BLUE_DARK()};
    border: 1px solid ${colors.WHITE(0.3)};
    border-radius: 6px;
  }
  .opener .signup-form .input__container:focus-within {
    box-shadow: 0 0 10px ${colors.WHITE(0.2)};
    border: 1px solid ${colors.WHITE(0.6)};
  }
  .opener .signup-form .input__container input {
    color: ${colors.WHITE()};
  }
  .opener .promo {
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background: ${colors.PURPLE_LIGHT(0.1)};
    color: ${colors.PURPLE_LIGHT()};
    font-size: 0.9rem;
    width: fit-content;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .opener .promo label {
    font-size: 0.7rem;
    color: ${colors.PURPLE_LIGHT()};
  }

  .promo__trusted {
    margin: 2rem 0;
    text-align: center;
  }
  .promo__trusted label {
    color: ${colors.WHITE()};
  }
  .promo__trusted__logos {
    display: flex;
    margin-top: 2rem;
    justify-content: center;
  }
  .promo__trusted__logos__logo {
    width: 6rem;
    margin-right: 2rem;
    filter: invert() grayscale();
    opacity: 0.6;
    transition: 250ms all;
    display: flex;
    align-items: center;
  }
  .promo__trusted__logos__logo:last-child {
    margin: 0;
  }
  .promo__trusted__logos__logo:hover {
    opacity: 1;
  }
  .promo__trusted__logos__logo .gatsby-image-wrapper {
    overflow: visible !important;
    width: 100%;
  }
  .startups-cta {
    padding: 12px 16px;
    background: ${colors.WHITE(0.1)};
    border-radius: 4px;
    width: fit-content;
    margin: auto;
    transition: 250ms all;
  }
  .startups-cta:hover {
    background: ${colors.WHITE(0.2)};
  }
  .startups-cta p,
  .startups-cta button {
    font-size: 16px;
    margin: 0;
  }
  .startups-cta a {
    color: ${colors.WHITE()};
  }
  .startups-cta svg {
    fill: ${colors.WHITE()};
    margin-bottom: -2px;
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 860px) {
    .opener h1 {
      font-size: 2.8rem;
      line-height: 3.3rem;
    }
    .opener__illustration svg {
      width: 36rem !important;
      height: 36rem !important;
      margin-top: -4rem;
      margin-left: 0rem;
    }
  }
  @media screen and (max-width: 600px) {
    .opener .signup-form {
      width: fit-content;
    }
    .opener__data {
      margin-top: 3rem;
    }
    .opener__illustration {
      display: none;
    }
  }
  @media screen and (max-width: 500px) {
    .opener .promo {
      font-size: 1.2rem;
      white-space: initial;
      background: none;
      padding: 0;
      line-height: 1.6rem;
    }
    .opener .signup-form {
      width: 100%;
    }
    .trusted__logos {
      flex-flow: column;
    }
    .trusted__logos__logo {
      margin: 1rem 0;
    }
  }

  @media screen and (max-width: 400px) {
    .opener .container__text {
      width: 100%;
    }
    .opener .container__image {
      bottom: -20%;
    }
  }
`;

const customerLogos: { [key: string]: string } = {
  'substack.png': 'https://substack.com',
  'demodesk.png': 'https://demodesk.com',
  'openphone.png':
    'https://www.openphone.co/blog/how-we-collect-organize-and-implement-customer-feedback/',
  'speechify.png': 'https://www.speechify.com',
};

const HomepageOpener: React.FC = () => {
  return (
    <Styled>
      <Section className="opener">
        <div className="section__gradient" />
        <Container className="container">
          <div>
            <h1>
              A Collaborative Repository
              <br />
              for User Feedback.
            </h1>
            <p>
              Herald helps product and customer teams work together to grow
              revenue by painting a full picture of user problems.
            </p>
            <Diagram />
            {/* <div className="signup-form">
              <CTADemo demo={true} />
            </div> */}
            <div className="promo__trusted">
              <label>Trusted by Customer Obsessed Teams</label>
              <div className="promo__trusted__logos">
                {Object.keys(customerLogos).map((key) => (
                  <a
                    key={key}
                    href={customerLogos[key]}
                    className="promo__trusted__logos__logo"
                    target="_blank"
                  >
                    <Image src={`logos/${key}`} alt={key} />
                  </a>
                ))}
              </div>
            </div>
            <div className="startups-cta">
              <p>
                <Link to="/startups">
                  <strong>
                    Herald also works for startups seeking product-market fit.{' '}
                  </strong>
                  <button>
                    <strong>Learn more</strong>
                  </button>{' '}
                  <icons.Arrow />
                </Link>
              </p>
            </div>
          </div>
        </Container>
      </Section>
    </Styled>
  );
};

export default HomepageOpener;
