import React from 'react';
import styled from 'styled-components';
import { icons, colors } from 'herald-fe-shared';
import { Link } from 'gatsby';

import { Container } from '~/layout/Layout';

const Styled = styled.div`
  margin-top: 6rem;
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  .container {
    max-width: 60rem;
  }
  h2 {
    margin-top: 1rem;
    color: ${colors.BLUE_DARK()};
  }
  h4 {
    margin: 1rem 0;
  }
  p svg {
    fill: ${colors.PURPLE_LIGHT()};
    margin-bottom: -2px;
  }
  @media screen and (max-width: 500px) {
  }
`;

const HomepageStartups: React.FC = () => {
  return (
    <Styled>
      <Container className="container">
        <label>Herald For</label>
        <h2>Smaller Startups</h2>
        <h4 className="text-gray">
          Herald’s suite of tools can help startups of every size organize and
          quantify customer feedback to make better products.
        </h4>
        <p>
          <strong>
            <Link to="/startups">
              Learn more <icons.Arrow />
            </Link>
          </strong>
        </p>
      </Container>
    </Styled>
  );
};

export default HomepageStartups;
