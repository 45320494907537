import React from 'react';
import styled from 'styled-components';
import { colors } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';

const Styled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;

  .container--grid {
    position: relative;
  }
  .container__grid__line {
    position: absolute;
    left: 0;
    width: 1px;
    height: 100%;
    border-left: 1px dashed ${colors.GRAY_3(0.2)};
  }
  .container__grid__line:nth-child(2) {
    left: 25%;
  }
  .container__grid__line:nth-child(3) {
    left: 50%;
  }
  .container__grid__line:nth-child(4) {
    left: 75%;
  }
  .container__grid__line:nth-child(5) {
    left: 100%;
  }

  @media screen and (max-width: 1200px) {
  }

  @media screen and (max-width: 1024px) {
  }

  @media screen and (max-width: 800px) {
  }

  @media screen and (max-width: 500px) {
  }

  @media screen and (max-width: 400px) {
  }
`;

const HomepageGrid: React.FC = () => {
  return (
    <Styled className="container__parent">
      <Container className="container--grid">
        <div className="container__grid__line" />
        <div className="container__grid__line" />
        <div className="container__grid__line" />
        <div className="container__grid__line" />
        <div className="container__grid__line" />
      </Container>
    </Styled>
  );
};

export default HomepageGrid;
